/* *************TOP STYLING*********** */
.container > .top {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1rem;
}

.container > .top > h1:hover {
  color: #1b9de4;
}
.container > .top > h1 {
  margin: 0.685rem 0;
  color: #1b9de4;
  cursor: pointer;
}
.container > .top > Button {
  background-color: #1b9de4 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  display: flex !important;
  align-items: center !important;
  padding: 0.4rem 1rem;
  cursor: pointer;
}
.container > .top > Button > img {
  margin-right: 0.5rem;
  height: 15px;
}

.imgStyle {
  cursor: pointer;
  width: 100px;
  margin: auto;
  border-radius: 4px;
  padding: 5px 10px;
  text-align: center;
  background-color: #1b9de4;
  color: #fff;
  font-size: 14px;
}
/************ FORM STYLING*********/
.form__container {
  background-color: #0c4767;
  padding: 30px 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.form__control {
  width: 100% !important;
  /* border: 1px solid red; */
  /* margin: 0 auto; */
}
.form__control > .inputContainer {
  display: flex;
  margin-top: 1.5rem;
  gap: 1rem;
  /* border: 1px solid red; */
}
.inputContainer > label,.inputContainer label span {
  /* border: 1px solid red; */
  display: block;
  width: 50%;
}
.fullWidth {
  width: 100% !important;
}
.halfWidth {
  width: 50% !important;
}
.textArea > label > span,
.inputContainer > label > span {
  font-size: 0.8rem;
  font-weight: 100;
  color: #f3eeee;
  margin-bottom: 10px;
}
.inputContainer > label > input {
  border: 2px solid #1b9de4;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  /* color: rgba(244, 244, 244, 0.5); */
  color: #000;
  width: 100% !important;
}
.inputContainer > label > input::placeholder {
  color: #746c6c;
}

.textArea {
  margin-top: 1.5rem;
  /* border: 1px solid red; */
}

.textArea > label > textarea {
  width: 97.3%;
}

.textArea > label .uploadImage {
  margin-top: 1.5rem;
}
.imageInput {
  border: 2px solid #1b9de4;
  background: rgba(255, 255, 255, 0.09);
  padding: 30px 0;
  border-radius: 4px;
  width: 65%;
  text-align: center;
}
.imageInput > .signatureSpan,
.imageInput > .sizeSpan {
  font-size: 12px;
}
.imageInput > .sizeSpan {
  opacity: 0.62;
  font-weight: 100;
}
.imageInput > label > span {
  color: #ffffff;
  background: #1b9de4;
  padding: 5px 25px;
  border-radius: 25px;
  font-size: 11px;
  display: inline-block;
  margin-top: 1rem;
}
.btn {
  color: #ffffff;
  background: #1b9de4;
  padding: 10px 30px;
  cursor: pointer;
  text-transform: capitalize;
  margin-top: 1.5rem;
  border: none;
  border-radius: 4px;
}

.form__control > button {
  color: #ffffff;
  background: #1b9de4;
  padding: 10px 30px;
  cursor: pointer;
  text-transform: capitalize;
  margin-top: 1.5rem;
  border: none;
  border-radius: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.toast-success-message {
  background-color: #357732 !important;
  color: #ffffff !important;
}
.client__image {
  display: flex;
  justify-content: space-between;
  height: 120px;
  /* border: 1px solid red; */
}
.imgPreview {
  width: 33%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1b9de4;
  border-radius: 5px;
  color: gray;
  height: 100%;
}
.imgPreview > span {
  color: grey;
}
.imgPreview > .image {
  width: 100%;
  height: 100%;
  /* height: max-content; */
  border-radius: 50% !important;
  /* border-radius: 0 !important; */
  border: none;
}

.passwordIcon {
  margin-right: 0.5rem;
  cursor: pointer;
}

.show__password {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  background-color: #fff;
}

.show__password > input {
  width: 100%;
  outline: none;
  padding: 9.5px 10px;
  /* border: 1px solid #3e3b5b; */
  background: transparent;
  color: #000000;
  border: none;
}
.checkBox {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  margin-top: 5rem;
  margin-bottom: 10px;
}

.checkBox > p {
  font-size: 0.8rem;
  margin-left: 10px;
}

/* Styling for item-selection... brand etc */
.item__selection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.brand_selection {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 1rem 0;
  margin-top: 1.5rem;
  
}.brand_selection>h4{
  margin: 0.5rem 0;
}

.BUH_selection {
  border: 1px solid #1b9de4;
  text-transform: capitalize;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px 5px;
  width: 50%;
}
/* Styling for item-selection... brand etc */

.checkboxContainer{
  margin: 20px 0;
}

.checkboxContainer>h4{
  margin: 5px 0;
}
.checkboxContainer div{
  display: flex;
  gap: 10px;
}

.checkboxContainer>div{
  display: flex;
  flex-direction: column;
  gap: 5px;
 
}
.checkboxContainer input:hover{
  cursor: pointer;
}

@media screen and (max-width: 768.8px) {
  .client__image {
    height: auto;
    flex-direction: column;
    gap: 15px;
  }
  .imageInput {
    width: 100%;
  }
  .imgPreview {
    height: 200px;
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .form__control > .inputContainer {
    flex-direction: column;
    margin-top: 0.5rem;
  }
  .inputContainer > label {
    width: 100% !important;
    margin: 10px 0px;
  }
}
@media screen and (max-width: 480px) {
  .imageInput > .signatureSpan,
  .imageInput > .sizeSpan {
    font-size: 10px;
  }
}
