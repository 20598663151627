.container {
  background-color: #0c4767;
  padding: 0.7rem 2rem;
  border-radius: 8px;
}
.topbar__container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.overview {
  display: flex;
  flex-direction: column;
}
.overview > span:nth-child(1) {
  font-size: 1.7rem;
  margin-bottom: 0.3rem;
}
.search__container {
  display: flex;
  display: none;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  border: 1px solid #ffffff;
  border-radius: 25px;
  width: 500px;
  max-width: 500px;
}
.search__container > input {
  background: transparent;
  padding: 0.7rem;
  border: none !important;
  outline: none !important;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
}
.search__container > input::placeholder {
  color: #ffffff;
  font-family: inherit;
}
.user__container,
.user {
  display: flex;
  align-items: center;
}
.user {
  gap: 0.6rem;
}
.user_detail {
  display: flex;
  flex-direction: column;
}
.user_detail > span:nth-child(1) {
  font-size: 1rem;
}
.user_detail > span:nth-child(2) {
  font-size: 0.7rem;
  color: rgb(250, 250, 250);
}
.user > img:nth-child(1) {
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

.mobile_menu_btn {
  display: none;
  background: transparent;
  color: #ab54db;
  border: 1px solid #ab54db;
  border-radius: 7px;
  padding: 2px 3px;
  height: 30px;
  margin-top: 26px;
}
@media screen and (max-width: 769px) {
  .container {
    gap: 1rem;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .mobile_menu_btn {
    display: block;
  }
}
