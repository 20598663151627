.container {
  height: 100%;
  padding: 2rem 0rem 6rem 0rem;
  border-radius: 2rem;
  background-color: #0c4767;
  position: fixed;
  width: 15vw;
}

.img__container {
  display: flex;
  justify-content: center;
}
.img__container > img {
  height: 85px;
  object-fit: contain;
  cursor: pointer;
  text-align: center;
}

.buttons {
  margin-top: 1rem;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.buttons a {
  width: 80%;
  padding: 1rem 2rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 8px;
  text-decoration: none;
  transition: 0.4s all;
}

.buttons a:hover {
  color: #fff;
  background-color: #1b9de4;
}
.buttons .active {
  color: #fff;
  background-color: #000;
}

.link_active {
  color: #ffffff;
  font-weight: 500;
  background-color: #1b9de4;
}

.buttons a img {
  width: auto;
  height: 1.2rem;
  object-fit: contain;
}

@media screen and (max-width: 1500px) {
  .container {
    padding: 2rem 0;
    border-radius: 1.6rem;
  }
  .buttons {
    width: 125% !important;
    align-items: flex-start;
  }
}

@media screen and (min-width: 950px) and (max-width: 1400px) {
  .container {
  }
  .buttons {
    zoom: 80%;
  }
}
@media screen and (max-width: 950px) {
  .container {
    position: relative;
  }

  .buttons a {
    font-size: 12px;
  }
  .container > img {
    height: 60px;
    margin: 0 1rem;
    cursor: pointer;
  }
}

@media screen and (max-width: 769px) {
  .container > img {
    display: none;
  }
  .buttons {
    margin-top: 4rem;
  }
  .buttons a {
    padding: 1rem 0;
    width: auto;
    margin: 0 auto;
  }
  .buttons a > span {
    display: none;
  }
  .link_active {
    color: #ffffff !important;
    font-weight: 500;
    background-color: #0c4767;
  }
}
@media screen and (max-width: 480px) {
  .buttons a {
    padding: 1rem 0;
    margin: unset;
  }
  .mobile_menu_btn {
    display: block;
  }
  .container {
    display: none;
  }
  .mobile_container {
    position: absolute;
    transition: all 1s ease;
    top: 90px;
    z-index: 100;
    width: 37%;
    border-radius: 0;
    background-color: #0c4767;
    padding: 0 14px;
    transition-delay: 1s;
  }
  .mobile_container > img {
    display: none;
  }

  .link_active {
    color: #ffffff !important;
    font-weight: 500;
    background-color: #0c4767;
  }
  .buttons a > span {
    display: inline-block;
  }
}
